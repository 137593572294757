import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import Button from '~components/Button'
import Image from '~components/Image'
import { breakpoints } from '~styles/global'
import RichText from '~components/RichText'
import { graphql, useStaticQuery } from "gatsby"
import resolveLink from "~utils/resolveLink"

const MakerSpotlight = ({ className }) => {

  const { mobile, tablet } = breakpoints
  
  const data = useStaticQuery(graphql`
  query MyQuery {
    sanitySiteSettings(id: {eq: "-0f217bb5-f7f6-5420-b7c6-58db2c12b8c7"}) {
        featuredMaker {
          _rawContent
          title
          slug {
            current
          }
          _type
        }
      }
    }
  `)
  const featuredMaker = data.sanitySiteSettings?.featuredMaker

  return (
    <div 
      className={className}
      css={css`
        background-color: var(--orange);
        display: grid;
        grid-template-columns: repeat(12, 1fr);
    `}>
      <div css={css`
        text-align: center;
        grid-column: span 6;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 7rem;
        padding: 0 3rem;
        align-items: stretch
        ${tablet} {
          margin-top: 4rem;
        }
        ${mobile} {
          grid-column: span 12;
          order: 2;
          margin-top: 0rem;
        }
      `}>
        <h2 css={css`margin-bottom: 0.2em;`}>Maker Spotlight</h2>
        <h1 className="serif" css={css`
          margin-bottom: 0.7em;
        `}>{featuredMaker.title}</h1>
        <div css={css`
          margin: auto 0 8rem;
          ${tablet} {
            margin: auto 0 4rem;
          }
          ${mobile} {
            margin: auto 0 6rem;   
          }
        `}>
          <div css={css`
            max-width: 410px;
            margin: 0 auto 3rem;
          `}>
            <RichText content={featuredMaker._rawContent.shortText}></RichText>
          </div>
          <Button
            primary={false}
            to={resolveLink(featuredMaker._type, featuredMaker.slug?.current)}
            large={true}
          >
           More about {featuredMaker._rawContent.shortName}
          </Button>
        </div>
      </div>
      <div css={css`
          grid-column: span 6;
          height: 100%;
          ${mobile} {
            grid-column: span 12;
            order: 1;
            margin-bottom: 4rem;
            height: auto;
          }
        `}>
        <Image asset={featuredMaker?._rawContent?.headerImage} aspectRatio={1} css={css`
          height: 100%;
          .gatsby-image-wrapper {
            height: 100%;
          }
        `}/>
      </div>
    </div>
  )
}

MakerSpotlight.propTypes = {
  makerName: PropTypes.string,
  buttonText: PropTypes.string,
  to: PropTypes.string,
  shortDesc: PropTypes.string,
  image: PropTypes.object,
}

MakerSpotlight.defaultProps = {
  makerName: 'Carl Durkow',
  shortDesc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut diam ipsum, scelerisque ut mollis sit amet.',
  buttonText: 'More about Carl',
  to: '/test',
}

export default MakerSpotlight
